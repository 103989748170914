
import { mapGetters } from 'vuex'
import baseLogic from '~/mixins/base-logic'
export default {
    mixins: [baseLogic],
    data() {
        return {
            isMobileScreen: false,
            links: {
                login: '/login',
            },
        }
    },
    computed: {
        ...mapGetters({
            languages: 'settings/languages',
        }),
        token() {
            return this.$store.state.player.token || this.$cookie.get('token')
        },
        loading() {
            return this.$store.state.settings.loading
        },
    },
    created() {
        if (this.$vuetify.breakpoint.mobile) {
            this.$vuetify.breakpoint.mobile = false
        }
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
    },
    async mounted() {
        this.$store?.dispatch('app/checkIsApp')

        await this.$store.dispatch('cms/cms')
        if (this.$cookie.get('token')) {
            this.socket = await this.$nuxtSocket({
                reconnection: false,
                auth: {
                    Authorization: `Bearer ${this.$cookie.get('token')}`,
                },
                transports: ['websocket'],
            })
            this.socket.on('playerBalance', (msg) => {
                this.$store.dispatch('player/totalBalanceSocket', msg)
            })
        }
        await this.$store.dispatch('banks/accounts')
        await this.$store.dispatch(
            'themes/language-string/fetchLanguageStrings',
            this.handleCookie('wl_locale')
        )
        await this.setDefaultLanguages()
        this.$store.dispatch('settings/languages')
    },
    methods: {
        onResize() {
            if (process.browser) {
                this.isMobileScreen = window.innerWidth < 1300
                this.$store.commit(
                    'themes/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
    },
}
